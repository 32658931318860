<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <div class="flex-c-c">
        <el-input
          type="text"
          placeholder="考核规则/考核对象名称"
          v-model="keywords"
          style="width: 200px"
          show-word-limit
        >
        </el-input>
        <el-select
          v-model="currentState"
          style="width: 240px;margin-left:20px;margin-right:20px"
          placeholder="请选择状态"
        >
          <el-option
            v-for="(item) in stateList"
            :key="item.type"
            :label="item.state"
            :value="item.type"
          />
        </el-select>
        <el-date-picker
          class="w300"
          v-model="createTime"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="queryAppointmentPage"
          >查询</el-button
        >
      </div>
      
      <el-button
          class="v-primary ml10"
          type="primary"
          @click="addRules"
          >新增考核规则</el-button
        >
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        
        <el-table-column prop="kpiId" align="center" label="序号" />
        <el-table-column prop="name" show-overflow-tooltip  align="center" label="考核规则名称" />
        <el-table-column width="400px" show-overflow-tooltip  align="center" label="考核内容">
          <template slot-scope="scope">
            {{scope.row.rules | rulesText}}
          </template>
        </el-table-column>
        <el-table-column width="200px" show-overflow-tooltip align="center" label="考核时间周期" >
          <template slot-scope="scope">
            <span>
              {{scope.row.startTime}} - {{scope.row.endTime}}
            </span>
            
          </template>
        </el-table-column>

        <el-table-column prop="status" align="center" label="状态">
          <template slot-scope="scope">
            <span :class="scope.row.status == 3 ? 'corange' : ''">
              {{scope.row.status == 1 ? '未开始' : scope.row.status == 2 ? '进行中' : scope.row.status == 3 ? '已结束' : ''}}
            </span>
            
          </template>
        </el-table-column>
        <el-table-column prop="endTime" width="250" align="center" label="考核对象">
          <template slot-scope="scope">
              {{scope.row.employees | memberText}}
            </template>
        </el-table-column>
        <el-table-column prop="process" align="center" label="考核达标率">
          <template slot-scope="scope">
            {{scope.row.process}}%
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="操作">
          <template slot-scope="scope">
            <span
              style="cursor: pointer;margin-right:10px"
              :class="'corange'"
              @click="revocationBtn(scope.row)"
            >
              撤销
            </span>
            <span
              style="color: $color-1; cursor: pointer;margin-right:10px"
              @click="editForm(scope.row.kpiId)"
            >
              {{(scope.row.status == 3 ) ?  '' : '编辑'}}
            </span>
            <span
              style="color: $color-1; cursor: pointer;margin-right:10px"
              @click="detall_tap(scope.row.kpiId)"
            >
              详情
            </span>
            
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import {
  fetchProductsTypeList,
} from "@/api/companyManage.js";
import {
  employeeKpiList,
  deleteEmployeeKpi
} from "@/api/employee.js";

import { getDataTimeSec } from "@/utils";

export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      value: "",
      optionList: "", //全部分类
      tableData: [],
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      createTime:'',
      start: '',
      end: '',
      jurisdiction:'',
      keywords:'',
      stateList:[
        {
          type:0,
          state:"全部"
        },
        {
          type:1,
          state:"未开始"
        },
        {
          type:2,
          state:"进行中"
        },
        {
          type:3,
          state:"已结束"
        }
      ],
      currentState:0
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      
      this.jurisdiction = val.includes("022")
      console.log(val.includes("022"),'this.jurisdiction')
      console.log(this.jurisdiction,'this.jurisdiction')
    },
    createTime(val,old){
      console.log(val)
      if(val){
        this.start =  getDataTimeSec(this.createTime[0], 'yy-mm-dd') +" 00:00:00"
        this.end = getDataTimeSec(this.createTime[1], 'yy-mm-dd') +" 23:59:59" 
      }else{
        this.start = ''
        this.end = ''
      }
      
    },
  },
  filters: {
    rulesText(val){
      let text = ''
      let obj = {
        1: "添加客户数",
        2: "新用户访客数",
        3: "跟进客户率",
        4: "商城销售金额"
      };
      val.forEach((item,index) => {
        text += obj[item.type] + item.value + (item.type == 1 ? '个'  : item.type == 2 ?  '个' : item.type == 3 ? '%' : item.type == 4 ? '元' : '') + '/'
      });
      return text
    },
    memberText(val){
      let names =  '' 
       val.forEach((item,index)=>{
         names +=  item.nickeName+'、'
       })
       if(names.length > 15){
         names = names.slice(0,10) + '...(' +  (val.length + '人)')
       }
       return  names
    }
  },
  mounted() {
    console.log(this.userInfo)
    this.getTableData();
    this.getTypeList();
  },
  methods: {
    //请求预约数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        startTime:this.start || '2000-09-23 00:00:00',
        endTime:this.end || '2022-10-23 00:00:00',
        status:this.currentState,
        keywords:this.keywords
      };
      employeeKpiList(data)
      .then((res) => {
        this.loading = false;
        this.tableData = res.data.pageInfo.list || "";
        this.total = res.data.pageInfo.total;
      })
      .catch((err) => {
        this.loading = false;
        this.tableData = [];
        console.log(err);
      });
    },
    //查询分类
    getTypeList() {
      fetchProductsTypeList({ ignore: true })
        .then((res) => {
          this.optionList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {
      this.currentPage = 1;
      this.pageNum = 1;
      this.getTableData();
      
    },
    //新增表单
    addRules(){
      this.$router.push(`/theInspectionRules`)
    },
    //编辑表单
    editForm(id){
      // if(this.jurisdiction || this.userInfo.roleId){
      //   console.log(this.jurisdiction,'this.jurisdiction')
        
        
      // }else{
      //   this.$message({
      //       type: "warning",
      //       message: "暂无权限",
      //     });
      //   return
      // }
      this.$router.push({ path:`/theInspectionRules?id=${id}` });
      // if(!this.userInfo.roleId){
      //   this.$message({
      //     type: "warning",
      //     message: "无操作权限",
      //   });
      //   return
      // }
      
    },
    //详情按钮
    detall_tap(id){
      // this.$router.push({
      //   name:'formDetall',
      //   params:{
      //     id:id
      //   }
      // });
      this.$router.push({ path:`/theAssessmentDetails?id=${id}` });
    },
    //筛选时间
    dateChange(val) {
      this.queryDate = getDataTimeSec(val, "yy-mm-dd");
    },
    //取消
    cancel(val) {
      
    },
    revocationBtn(val){
      this.$confirm("确定撤销此考核规则吗？删除后不可恢复！", "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
      .then(() => {
        let data = {
          kpiId:val.kpiId
        };
        deleteEmployeeKpi(data)
        .then(res=>{
          console.log(res)
          if(res.code == 200){
            this.$message.success('成功撤销！')
            this.getTableData()
          }
        })
      })
      .catch((err) => {
        console.log(err);
        this.$message({
          type: "info",
          message: "取消输入",
        });
      });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>